import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Formikk } from "../components/Formik"

const formUrl = process.env.FIREBASE_CONTACT_FORM_URL
const formAccessKey = process.env.FIREBASE_CONTACT_FORM_ACCESSKEY
const turnstileSiteKey = process.env.CLOUDFLARE_TURNSTILE_SITEKEY

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          contact {
            name
            phone
            phoneFormatted
            email
            facebook
          }
        }
      }
      szentes: file(relativePath: { eq: "ezermester-fokep.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      furdoszoba: file(relativePath: { eq: "Furdo.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      konyha: file(relativePath: { eq: "konyha.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      nappali: file(relativePath: { eq: "Nappali.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      terasz: file(relativePath: { eq: "terasz.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const imageData = data.szentes.childImageSharp.fluid
  const imageFurdoszobaData = data.furdoszoba.childImageSharp.fluid
  const imageKonyhaData = data.konyha.childImageSharp.fluid
  const imageNappaliData = data.nappali.childImageSharp.fluid
  const imageTeraszData = data.terasz.childImageSharp.fluid

  return (
    <Layout>
      <SEO title="Burkolás" description="Külső- belső burkolás - Bízza ránk!" />

      <BackgroundImage
        Tag="section"
        id="hero"
        className="d-flex align-items-center"
        fluid={imageData}
        backgroundColor={`white`}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-2">
              <h1>
                Üdvözöljük a <span>BurkolóTeam</span> oldalán
              </h1>
            </div>
          </div>
        </div>
      </BackgroundImage>

      <section id="featured-services" className="featured-services">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
              <div className="icon-box konyha">
                <BackgroundImage
                  Tag="div"
                  className="boxok"
                  fluid={imageKonyhaData}
                  backgroundColor={`white`}
                />
                <div className="textContent d-flex flex-column justify-content-center">
                  <div className=" d-flex flex-column justify-content-center">
                    <h4 className="title">
                      <a href="#">Konyha, előszoba</a>
                    </h4>
                    <p className="description">
                      burkolása különböző méretű és anyagú fali csempékkel és
                      padlóburkolóval.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
              <div className="icon-box furdo">
                <BackgroundImage
                  Tag="div"
                  className="boxok"
                  fluid={imageFurdoszobaData}
                  backgroundColor={`white`}
                />

                <div className="textContent d-flex flex-column justify-content-center">
                  <h4 className="title">
                    <a href="#">Fürdőszoba, wc</a>
                  </h4>
                  <p className="description">
                    burkolása különböző méretű és anyagú fali csempékkel és
                    padlóburkolóval.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
              <div className="icon-box nappali">
                <BackgroundImage
                  Tag="div"
                  className="boxok"
                  fluid={imageNappaliData}
                  backgroundColor={`white`}
                />
                <div className="textContent d-flex flex-column justify-content-center">
                  <div className=" d-flex flex-column justify-content-center">
                    <h4 className="title">
                      <a href="#">Nappali, egyéb helyiségek</a>
                    </h4>
                    <p className="description">
                      burkolása különböző méretű és anyagú padlóburkolóval,
                      lamináltpadlóval
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-lg-0">
              <div className="icon-box terasz" style={{ width: "100%" }}>
                <BackgroundImage
                  Tag="div"
                  className="boxok"
                  fluid={imageTeraszData}
                  backgroundColor={`white`}
                />
                <div className="textContent d-flex flex-column justify-content-center">
                  <div className=" d-flex flex-column justify-content-center">
                    <h4 className="title">
                      <a href="#">Teraszok</a>
                    </h4>
                    <p className="description">burkolása, kövezése</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about section-bg">
        <div className="container">
          <div className="section-title">
            {/* <h2>Rólunk</h2> */}
            {/* <h3>
              Kik vagyunk és <span>miért dolgozunk ingyen?</span>
            </h3> */}
            <p>
              Elsősorban beltéri burkolásokat végzünk (csempe, járólap, vinyl-
              és laminált parketta alapanyagokkal; fürdőszobák, konyhák, wc-k,
              nappalik, előszobák és egyéb helyiségek padlóinak, falainak
              burkolását).
            </p>
            <p>
              Évszaktól függően kültéri munkálatok (pl. terasz, autóbehajtók)
              megrendelésére is van lehetőség.
            </p>
            <p>
              <a
                href={data.site.siteMetadata.contact.facebook}
                target={"_blank"}
                rel={"noreferrer"}
                className="facebook"
                aria-label="facebook"
              >
                Facebook oldalunkon
              </a>{" "}
              gyakrabban frissülő referenciánkból is láthat néhányat.
            </p>
          </div>
        </div>
      </section>

      {/* <section id="skills" className="skills">
        <div className="container">
          <div className="row skills-content">
            <div className="col-lg-6">
              <div className="progress">
                <span className="skill">
                  HTML <i className="val">100%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  CSS <i className="val">90%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="90"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  JavaScript <i className="val">75%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="progress">
                <span className="skill">
                  PHP <i className="val">80%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="80"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  WordPress/CMS <i className="val">90%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="90"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  Photoshop <i className="val">55%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="55"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section id="counts" className="counts">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="count-box">
                <i className="bi bi-emoji-smile"></i>
                <span
                  data-purecounter-start="0"
                  data-purecounter-end="232"
                  data-purecounter-duration="1"
                  className="purecounter"
                ></span>
                <p>Happy Clients</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
              <div className="count-box">
                <i className="bi bi-journal-richtext"></i>
                <span
                  data-purecounter-start="0"
                  data-purecounter-end="521"
                  data-purecounter-duration="1"
                  className="purecounter"
                ></span>
                <p>Projects</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
              <div className="count-box">
                <i className="bi bi-headset"></i>
                <span
                  data-purecounter-start="0"
                  data-purecounter-end="1463"
                  data-purecounter-duration="1"
                  className="purecounter"
                ></span>
                <p>Hours Of Support</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
              <div className="count-box">
                <i className="bi bi-people"></i>
                <span
                  data-purecounter-start="0"
                  data-purecounter-end="15"
                  data-purecounter-duration="1"
                  className="purecounter"
                ></span>
                <p>Hard Workers</p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section id="clients" className="clients section-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <StaticImage
                src="../images/logo/mapei.png"
                placeholder={"blurred"}
                layout="constrained"
                alt=""
                height={100}
                // width={120}
              />
            </div>

            <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <StaticImage
                src="../images/logo/cersanit.jpg"
                placeholder={"blurred"}
                layout="constrained"
                alt=""
                height={100}
                // width={120}
              />
            </div>

            <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img
                src="images/clients/client-3.png"
                className="img-fluid"
                alt=""
              />
            </div>

            <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img
                src="images/clients/client-4.png"
                className="img-fluid"
                alt=""
              />
            </div>

            <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img
                src="images/clients/client-5.png"
                className="img-fluid"
                alt=""
              />
            </div>

            <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img
                src="images/clients/client-6.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </section> */}

      {/* <section id="services" className="services">
        <div className="container">
          <div className="section-title">
            <h2>Burkolás</h2>
            <h3>
              Alábbi <span>munkákat</span> vállaljuk
            </h3>
            <p>
              Ut possimus qui ut temporibus culpa velit eveniet modi omnis est
              adipisci expedita at voluptas atque vitae autem.
            </p>
          </div>

          <div className="row">
            <div
              className="col-lg-4 col-md-6 d-flex align-items-stretch"
             
             
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4>
                  <a href="">Hidegburkolás</a>
                </h4>
                <p>
                  Voluptatum deleniti atque corrupti quos dolores et quas
                  molestias excepturi
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
             
             
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-file"></i>
                </div>
                <h4>
                  <a href="">Sed ut perspiciatis</a>
                </h4>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
             
             
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-tachometer"></i>
                </div>
                <h4>
                  <a href="">Magni Dolores</a>
                </h4>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
             
             
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-world"></i>
                </div>
                <h4>
                  <a href="">Nemo Enim</a>
                </h4>
                <p>
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui
                  blanditiis
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
             
             
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-slideshow"></i>
                </div>
                <h4>
                  <a href="">Dele cardo</a>
                </h4>
                <p>
                  Quis consequatur saepe eligendi voluptatem consequatur dolor
                  consequuntur
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
             
             
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bx-arch"></i>
                </div>
                <h4>
                  <a href="">Divera don</a>
                </h4>
                <p>
                  Modi nostrum vel laborum. Porro fugit error sit minus sapiente
                  sit aspernatur
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section id="testimonials" className="testimonials">
        <div className="container">
          <div
            className="testimonials-slider swiper"
           
           
          >
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="testimonial-item">
                  <StaticImage
                    src="../images/melos5.jpg"
                    placeholder={"blurred"}
                    layout="constrained"
                    className="testimonial-img"
                    alt=""
                    aspectRatio={1}
                  />
                  <h3>Forró Fejű</h3>
                  <h4>Ceo &amp; Founder</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Proin iaculis purus consequat sem cure digni ssim donec
                    porttitora entum suscipit rhoncus. Accusantium quam,
                    ultricies eget id, aliquam eget nibh et. Maecen aliquam,
                    risus at semper.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <StaticImage
                    src="../images/melos4.jpg"
                    placeholder={"blurred"}
                    layout="constrained"
                    className="testimonial-img"
                    alt=""
                    aspectRatio={1}
                  />
                  <h3>Sara Wilsson</h3>
                  <h4>Designer</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Export tempor illum tamen malis malis eram quae irure esse
                    labore quem cillum quid cillum eram malis quorum velit fore
                    eram velit sunt aliqua noster fugiat irure amet legam anim
                    culpa.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <StaticImage
                    src="../images/melos3.jpg"
                    placeholder={"blurred"}
                    layout="constrained"
                    className="testimonial-img"
                    alt=""
                    aspectRatio={1}
                  />
                  <h3>Jena Karlis</h3>
                  <h4>Store Owner</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Enim nisi quem export duis labore cillum quae magna enim
                    sint quorum nulla quem veniam duis minim tempor labore quem
                    eram duis noster aute amet eram fore quis sint minim.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <img
                    src="images/testimonials/testimonials-4.jpg"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Matt Brandon</h3>
                  <h4>Freelancer</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Fugiat enim eram quae cillum dolore dolor amet nulla culpa
                    multos export minim fugiat minim velit minim dolor enim duis
                    veniam ipsum anim magna sunt elit fore quem dolore labore
                    illum veniam.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <img
                    src="images/testimonials/testimonials-5.jpg"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>John Larson</h3>
                  <h4>Entrepreneur</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Quis quorum aliqua sint quem legam fore sunt eram irure
                    aliqua veniam tempor noster veniam enim culpa labore duis
                    sunt culpa nulla illum cillum fugiat legam esse veniam culpa
                    fore nisi cillum quid.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </section> */}

      {/* <section id="portfolio" className="portfolio">
        <div className="container">
          <div className="section-title">
            <h2>Referenciáink</h2>
            <h3>
              Tekintse meg <span>munkáinkat</span>
            </h3>
            <p>
              Ut possimus qui ut temporibus culpa velit eveniet modi omnis est
              adipisci expedita at voluptas atque vitae autem.
            </p>
          </div>

          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center">
              <ul id="portfolio-flters">
                <li data-filter="*" className="filter-active">
                  Összes
                </li>
                <li data-filter=".filter-app">Burkolás</li>
                <li data-filter=".filter-card">Falazás</li>
                <li data-filter=".filter-web">Evés</li>
              </ul>
            </div>
          </div>

          <div
            className="row portfolio-container"
           
           
          >
            <div className="col-lg-4 col-md-6 portfolio-item filter-app">
              <StaticImage
                src="../images/melos6.jpg"
                placeholder={"blurred"}
                layout="constrained"
                alt=""
                aspectRatio={1.3}
              />
              <div className="portfolio-info">
                <h4>App 1</h4>
                <p>App</p>
                <a
                  href="images/portfolio/portfolio-1.jpg"
                  data-gallery="portfolioGallery"
                  className="portfolio-lightbox preview-link"
                  title="App 1"
                >
                  <i className="bx bx-plus"></i>
                </a>
                <a
                  href="portfolio-details.html"
                  className="details-link"
                  title="More Details"
                >
                  <i className="bx bx-link"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-web">
              <img
                src="images/portfolio/portfolio-2.jpg"
                className="img-fluid"
                alt=""
              />
              <div className="portfolio-info">
                <h4>Web 3</h4>
                <p>Web</p>
                <a
                  href="images/portfolio/portfolio-2.jpg"
                  data-gallery="portfolioGallery"
                  className="portfolio-lightbox preview-link"
                  title="Web 3"
                >
                  <i className="bx bx-plus"></i>
                </a>
                <a
                  href="portfolio-details.html"
                  className="details-link"
                  title="More Details"
                >
                  <i className="bx bx-link"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-app">
              <img
                src="images/portfolio/portfolio-3.jpg"
                className="img-fluid"
                alt=""
              />
              <div className="portfolio-info">
                <h4>App 2</h4>
                <p>App</p>
                <a
                  href="images/portfolio/portfolio-3.jpg"
                  data-gallery="portfolioGallery"
                  className="portfolio-lightbox preview-link"
                  title="App 2"
                >
                  <i className="bx bx-plus"></i>
                </a>
                <a
                  href="portfolio-details.html"
                  className="details-link"
                  title="More Details"
                >
                  <i className="bx bx-link"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-card">
              <img
                src="images/portfolio/portfolio-4.jpg"
                className="img-fluid"
                alt=""
              />
              <div className="portfolio-info">
                <h4>Card 2</h4>
                <p>Card</p>
                <a
                  href="images/portfolio/portfolio-4.jpg"
                  data-gallery="portfolioGallery"
                  className="portfolio-lightbox preview-link"
                  title="Card 2"
                >
                  <i className="bx bx-plus"></i>
                </a>
                <a
                  href="portfolio-details.html"
                  className="details-link"
                  title="More Details"
                >
                  <i className="bx bx-link"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-web">
              <img
                src="images/portfolio/portfolio-5.jpg"
                className="img-fluid"
                alt=""
              />
              <div className="portfolio-info">
                <h4>Web 2</h4>
                <p>Web</p>
                <a
                  href="images/portfolio/portfolio-5.jpg"
                  data-gallery="portfolioGallery"
                  className="portfolio-lightbox preview-link"
                  title="Web 2"
                >
                  <i className="bx bx-plus"></i>
                </a>
                <a
                  href="portfolio-details.html"
                  className="details-link"
                  title="More Details"
                >
                  <i className="bx bx-link"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-app">
              <img
                src="images/portfolio/portfolio-6.jpg"
                className="img-fluid"
                alt=""
              />
              <div className="portfolio-info">
                <h4>App 3</h4>
                <p>App</p>
                <a
                  href="images/portfolio/portfolio-6.jpg"
                  data-gallery="portfolioGallery"
                  className="portfolio-lightbox preview-link"
                  title="App 3"
                >
                  <i className="bx bx-plus"></i>
                </a>
                <a
                  href="portfolio-details.html"
                  className="details-link"
                  title="More Details"
                >
                  <i className="bx bx-link"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-card">
              <img
                src="images/portfolio/portfolio-7.jpg"
                className="img-fluid"
                alt=""
              />
              <div className="portfolio-info">
                <h4>Card 1</h4>
                <p>Card</p>
                <a
                  href="images/portfolio/portfolio-7.jpg"
                  data-gallery="portfolioGallery"
                  className="portfolio-lightbox preview-link"
                  title="Card 1"
                >
                  <i className="bx bx-plus"></i>
                </a>
                <a
                  href="portfolio-details.html"
                  className="details-link"
                  title="More Details"
                >
                  <i className="bx bx-link"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-card">
              <img
                src="images/portfolio/portfolio-8.jpg"
                className="img-fluid"
                alt=""
              />
              <div className="portfolio-info">
                <h4>Card 3</h4>
                <p>Card</p>
                <a
                  href="images/portfolio/portfolio-8.jpg"
                  data-gallery="portfolioGallery"
                  className="portfolio-lightbox preview-link"
                  title="Card 3"
                >
                  <i className="bx bx-plus"></i>
                </a>
                <a
                  href="portfolio-details.html"
                  className="details-link"
                  title="More Details"
                >
                  <i className="bx bx-link"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-web">
              <img
                src="images/portfolio/portfolio-9.jpg"
                className="img-fluid"
                alt=""
              />
              <div className="portfolio-info">
                <h4>Web 3</h4>
                <p>Web</p>
                <a
                  href="images/portfolio/portfolio-9.jpg"
                  data-gallery="portfolioGallery"
                  className="portfolio-lightbox preview-link"
                  title="Web 3"
                >
                  <i className="bx bx-plus"></i>
                </a>
                <a
                  href="portfolio-details.html"
                  className="details-link"
                  title="More Details"
                >
                  <i className="bx bx-link"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section id="team" className="team section-bg">
        <div className="container">
          <div className="section-title">
            <h2>Team</h2>
            <h3>
              A keményen dolgozó <span>csapatunk</span>
            </h3>
            <p>
              Ut possimus qui ut temporibus culpa velit eveniet modi omnis est
              adipisci expedita at voluptas atque vitae autem.
            </p>
          </div>

          <div className="row">
            <div
              className="col-lg-3 col-md-6 d-flex align-items-stretch"
             
             
            >
              <div className="member">
                <div className="member-img">
                  <StaticImage
                    src="../images/fonok.png"
                    placeholder={"blurred"}
                    layout="constrained"
                    alt=""
                    aspectRatio={1}
                  />
                </div>
                <div className="member-info">
                  <h4>Nagy Ispán</h4>
                  <span>A Főni</span>
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6 d-flex align-items-stretch"
             
             
            >
              <div className="member">
                <div className="member-img">
                  <StaticImage
                    src="../images/melos3.jpg"
                    placeholder={"blurred"}
                    layout="constrained"
                    alt=""
                    aspectRatio={1}
                  />
                </div>
                <div className="member-info">
                  <h4>Értet Lenke</h4>
                  <span>a "most akkor diagon áll?"</span>
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6 d-flex align-items-stretch"
             
             
            >
              <div className="member">
                <div className="member-img">
                  <StaticImage
                    src="../images/melos1.jpg"
                    placeholder={"blurred"}
                    layout="constrained"
                    alt=""
                    aspectRatio={1}
                  />
                </div>
                <div className="member-info">
                  <h4>Porrá Zúzó</h4>
                  <span>a könyékig</span>
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6 d-flex align-items-stretch"
             
             
            >
              <div className="member">
                <div className="member-img">
                  <StaticImage
                    src="../images/atanu.png"
                    placeholder={"blurred"}
                    layout="constrained"
                    alt=""
                    aspectRatio={1}
                  />
                </div>
                <div className="member-info">
                  <h4>Ding Dong</h4>
                  <span>kis jehova nagy Tanúja</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section id="faq" className="faq section-bg">
        <div className="container">
          <div className="section-title">
            <h2>GY.I.K</h2>
            <h3>
              Gyakran intézett <span>kérdések</span>
            </h3>
            <p>
              Ut possimus qui ut temporibus culpa velit eveniet modi omnis est
              adipisci expedita at voluptas atque vitae autem.
            </p>
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-10">
              <ul className="faq-list">
                <li>
                  <a
                    data-bs-toggle="collapse"
                    className="collapsed question"
                    href="#faq1"
                  >
                    Miért ilyen drága minden alapanyag?{" "}
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq1"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Feugiat pretium nibh ipsum consequat. Tempus iaculis urna
                      id volutpat lacus laoreet non curabitur gravida. Venenatis
                      lectus magna fringilla urna porttitor rhoncus dolor purus
                      non.
                    </p>
                  </div>
                </li>

                <li>
                  <a
                    data-bs-toggle="collapse"
                    href="#faq2"
                    className="collapsed question"
                  >
                    A szomszédnak is ajánlhatom Önöket?{" "}
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq2"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Dolor sit amet consectetur adipiscing elit pellentesque
                      habitant morbi. Id interdum velit laoreet id donec
                      ultrices. Fringilla phasellus faucibus scelerisque
                      eleifend donec pretium. Est pellentesque elit ullamcorper
                      dignissim. Mauris ultrices eros in cursus turpis massa
                      tincidunt dui.
                    </p>
                  </div>
                </li>

                <li>
                  <a
                    data-bs-toggle="collapse"
                    href="#faq3"
                    className="collapsed question"
                  >
                    Kutyasétáltatást is vállalnak, ha busásan megfizetem?{" "}
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq3"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Eleifend mi in nulla posuere sollicitudin aliquam ultrices
                      sagittis orci. Faucibus pulvinar elementum integer enim.
                      Sem nulla pharetra diam sit amet nisl suscipit. Rutrum
                      tellus pellentesque eu tincidunt. Lectus urna duis
                      convallis convallis tellus. Urna molestie at elementum eu
                      facilisis sed odio morbi quis
                    </p>
                  </div>
                </li>

                <li>
                  <a
                    data-bs-toggle="collapse"
                    href="#faq4"
                    className="collapsed question"
                  >
                    Önök tudják, hogy mit nevezünk sniccernek?{" "}
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq4"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Dolor sit amet consectetur adipiscing elit pellentesque
                      habitant morbi. Id interdum velit laoreet id donec
                      ultrices. Fringilla phasellus faucibus scelerisque
                      eleifend donec pretium. Est pellentesque elit ullamcorper
                      dignissim. Mauris ultrices eros in cursus turpis massa
                      tincidunt dui.
                    </p>
                  </div>
                </li>

                <li>
                  <a
                    data-bs-toggle="collapse"
                    href="#faq5"
                    className="collapsed question"
                  >
                    Elromlott a kerékpárom váltója, ahhoz is értenek?{" "}
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq5"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Molestie a iaculis at erat pellentesque adipiscing
                      commodo. Dignissim suspendisse in est ante in. Nunc vel
                      risus commodo viverra maecenas accumsan. Sit amet nisl
                      suscipit adipiscing bibendum est. Purus gravida quis
                      blandit turpis cursus in
                    </p>
                  </div>
                </li>

                <li>
                  <a
                    data-bs-toggle="collapse"
                    href="#faq6"
                    className="collapsed question"
                  >
                    Vályogházam van, ami megsülyedt. Érdemes vele foglalkozni,
                    vagy gyújtsam fel?{" "}
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq6"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Laoreet sit amet cursus sit amet dictum sit amet justo.
                      Mauris vitae ultricies leo integer malesuada nunc vel.
                      Tincidunt eget nullam non nisi est sit amet. Turpis nunc
                      eget lorem dolor sed. Ut venenatis tellus in metus
                      vulputate eu scelerisque. Pellentesque diam volutpat
                      commodo sed egestas egestas fringilla phasellus faucibus.
                      Nibh tellus molestie nunc non blandit massa enim nec.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section> */}

      <section id="contact" className="contact">
        <div className="container">
          <div className="section-title">
            <h2>Kapcsolat</h2>
            <h3>
              <span>Elérhetőségeink</span>
            </h3>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 offset-lg-2">
              <div className="info-box mb-4 email">
                <i className="bx bx-envelope"></i>
                <h3>Írjon nekünk</h3>
                <p>{data.site.siteMetadata.contact.email}</p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="info-box mb-4 phone">
                <i className="bx bx-phone-call"></i>
                <h3>Hívjon minket</h3>
                <p>
                  <a href={`tel:${data.site.siteMetadata.contact.phone}`}>
                    {data.site.siteMetadata.contact.phoneFormatted}
                  </a>
                </p>
              </div>
            </div>
          </div>

          {/* <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <Formikk formUrl={formUrl} accesskey={formAccessKey} />
            </div>
          </div> */}
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
